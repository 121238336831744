@import "/home/pdeadmin/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.RowFormatter {
  display: flex;
  align-items: center;
  min-height: 30px;
  width: 100%;

  text-align: initial;

  & .UserName {
    width: 250px;
    font-size: 14px;
    font-weight: 800;
  }
}
