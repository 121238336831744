@import "/home/pdeadmin/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;

  box-sizing: border-box;
}

.loader:before{
  border-radius: 50%;
  content: " ";
  width: 48px;
  height:48px;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;

  border-top: solid 6px #f0f3f5;
  border-right: solid 6px #f0f3f5;
  border-bottom: solid 6px #f0f3f5;
  border-left: solid 6px #f0f3f5;
}

.loader:after {
  position: absolute;
  content: " ";
  width: 48px;
  height: 48px;
  display: inline-block;
  top: 0;
  left: 0;


  border-radius: 50%;
  border-top: solid 6px #20a8d8;
  border-right: solid 6px transparent;
  border-bottom: solid 6px transparent;
  border-left: solid 6px transparent;
  box-sizing: border-box;

  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
