@import "/home/pdeadmin/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.PageContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  min-height: 100%;
}

.PageContent {
  width: 100%;
  margin: 100px 0px;

  padding-bottom: 50px;

  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.Animate {
  animation: appear .5s ease-in-out forwards;
}

@keyframes appear {
  0% {
    opacity: 0;
    //transform: scale(0);
  }
  100% {
    opacity: 1;
    //transform: scale(1);
  }
}

.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
