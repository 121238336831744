@import "/home/pdeadmin/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.Notification {
  &:global(.alert) {
    margin: 0;

    &:global(.alert-primary):hover {
      background-color: #20a8d8;
      border-color: #20a8d8;
    }

    &:global(.alert-danger):hover {
      background-color: #f09a97;
      border-color: #f09a97;
    }
  }

  & > :global(.close) {
    margin-left: auto;
  }
}
