@import "/home/pdeadmin/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
$width: 40px;
$padding: 2px;
$height: $width / 2 + $padding;

.SwitchWrapper {
  cursor: pointer;

  background: #15273b;
  padding: $padding;
  width: $width;
  height: $height;
  border-radius: $height / 2;

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.Switch {
  height: 100%;
  display: grid;
  grid-template-columns: 0fr 1fr 1fr;
  transition: .2s;

  &::after {
    content: '';
    border-radius: 50%;
    background: #ccc;
    grid-column: 2;
    transition: background .2s;
  }
}

input:checked {
  + .Switch {
    grid-template-columns: 1fr 1fr 0fr;

    &::after {
      background-color: #52cf71;
    }
  }
}