@import "/home/pdeadmin/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.PagePreview {
  padding: 5px;
}

.Image {
  cursor: zoom-in;
}

.ImagePreview {
  width: 700px;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
