@import "/home/pdeadmin/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.EventDetailsPage {
  position: relative;

  & .AuthorDetails {
    margin-bottom: 20px;
    overflow: hidden;

    @include clearfix;

    & > img {
      float: left;
      margin-right: 40px;
    }

    & .Description {
      padding: 0px 40px 20px 40px;
      text-align: justify;
    }
  }

  & .Edit {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  & .BookList {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 40px;
    list-style-type: none;
  }

  @include media-breakpoint-down(md) {
    & .AuthorDetails {
      display: flex;
      flex-direction: column;

      & > img {
        float: none;
        margin: 0px auto 20px;
      }
    }
  }

  & .ButtonGroup {
    position: absolute;
    top: 5px;
    right: 5px;

    & > *:not(:last-child) {
      margin-right: 8px;
    }
  }
}
