@import "/home/pdeadmin/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.profile {
  display: flex;
  width: 100%;
  height: 100%;

  ul {
    margin: 0;
    padding: 0;
  }

  ul li {
    list-style-type: none;
  }

  & .menu {
    width: 250px;
    height: 100%;

    background-color: $light;
    border-right: 1px solid $grey-300;

    @include media-breakpoint-down(md) {
      width: auto;

      & .item {
        & .navLink {
          & .linkText {
            display: none;
          }
        }
      }
    }

    & .item {
      .navLink {
        display: flex;
        align-items: center;
        padding: 8px 12px 7px;
        height: 40px;
        width: 100%;

        font-weight: 800;

        cursor: pointer;
        text-decoration: none;
        color: $grey-700;

        & .linkText { }
      }

      .navLink:hover {
        background-color: $grey-200;
        color: $dark;
      }

      .navLink.active {
        background-color: $grey-200;
        color: $dark;
      }
    }
  }

  .main {
    padding: 20px;
    width: 100%;

    & > :global(.card) {
      padding: 20px;
      height: 100%;
      margin-bottom: 0px;
    }
  }
}
