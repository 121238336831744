@import "/home/pdeadmin/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.FileInput {
  & .labelButton {
    display: flex;
    justify-content: center;
    min-width: 100px;
    padding: 6px 30px;
  }

  & .fileInput {
    width: 0.1px;
    height: 0.1px;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    opacity: 0;
  }
}
