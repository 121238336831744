@import "/home/pdeadmin/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.Heading {
  display: flex;
  align-items: center;
  padding: 8px;

  color: $header-color;
}

.HeadingIcon {
  margin-right: 10px;
}
