@import "coreui/coreui";


html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

button,
.bnt {
  outline: none !important;
}

.select-xs {
  height: 1.97em;
}

.react-tagsinput {
  width: 100%;

  .react-tagsinput-tag {
    border-radius: 2px;
    color: #fff;
    background-color: #4dbd74;
    border-color: #4dbd74;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
  }
}

.react-tagsinput--focused {
  border: 1px solid #ccc !important;
}

.publisherBooks {
  max-width: 280px;
}
